<template>
  <v-card class="segmented" flat>
    <v-card-title>
      <v-row>
        <v-col cols="12">
          <h5>
            Report:
            {{ $t(`reporting.reportTitle.${this.$route.params.reportType}`) }}
          </h5>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle class="mt-0">{{
      $t('reporting.additionalDataInfo')
    }}</v-card-subtitle>
    <v-card-text>
      <div class="text-center" v-if="loading">
        <div class="text-uppercase muted mb-4">{{ $t('dataLoading') }}</div>
        <v-progress-linear indeterminate></v-progress-linear>
      </div>

      <div v-else>
        <v-row>
          <v-col cols="12" md="4" offset-md="8">
            <v-select
              :items="talkroundItems"
              v-model="talkroundId"
              :label="$t('feedbacks.talkround')"
            ></v-select>
          </v-col>
        </v-row>

        <div class="text-right">
          <v-btn @click="downloadReport" :loading="downloading">
            <v-icon class="mr-2">mdi-file-excel-outline</v-icon>
            {{ $t('reporting.download') }}
          </v-btn>
        </div>

        <status-report-table
          :headers="headers"
          :report-rows="reportRows"
        ></status-report-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import reportingService from '@/services/ReportingService.js';
import talkroundService from '@/services/TalkroundService.js';
import statusReportTable from '@/components/reporting/status-report-table.vue';

export default {
  data: () => ({
    loading: true,
    downloading: false,
    statusReport: null,
    reportData: null,
    options: {},
    series: [],
    talkrounds: [],
    talkroundId: null
  }),

  computed: {
    headers() {
      const data = [...this.reportData.result.columns];

      return data
        .filter((x) => x.showInUI)
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .map((x) => {
          return {
            index: x.sortIndex,
            label: x.itemName,
            type: x.dataType
          };
        });
    },

    talkroundItems() {
      const typeId = this.$route.params.processType;

      return this.talkrounds
        .filter((x) => x.processType === typeId)
        .map((x) => {
          const start = this.$d(new Date(x.startDateUtc), 'date');
          const end = this.$d(new Date(x.endDateUtc), 'date');

          return {
            value: x.id,
            text: `${start} - ${end}`
          };
        });
    },

    reportRows() {
      return [...this.reportData.result.rows].sort((a, b) => {
        // sort by last name
        if (a.columns[3].toLowerCase() > b.columns[3].toLowerCase()) {
          return 1;
        } else if (a.columns[3].toLowerCase() < b.columns[3].toLowerCase()) {
          return -1;
        } else {
          // and then by first name
          if (a.columns[4].toLowerCase() > b.columns[4].toLowerCase()) {
            return -1;
          } else if (a.columns[4].toLowerCase() < b.columns[4].toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    }
  },

  watch: {
    async talkroundId() {
      this.loading = true;
      await this.loadReport();
    }
  },

  async mounted() {
    this.talkrounds = await this.listTalkrounds();
    this.talkroundId = this.getTalkroundId();
  },

  methods: {
    async downloadReport() {
      this.downloading = true;

      const processType = this.$route.params.processType;

      const data = await reportingService.downloadReport(
        this.$route.params.reportType,
        processType,
        this.talkroundId,
        false
      );

      const blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      const fileUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.href = fileUrl;

      const fileName = this.$t(
        `reporting.reportFileName.${this.$route.params.reportType}`
      );

      const today = new Date().toISOString().slice(0, 10).replaceAll('-', '');
      anchor.download = `${today}_${fileName}.xlsx`;

      anchor.click();

      window.URL.revokeObjectURL(fileUrl);

      this.downloading = false;
    },

    getTalkroundId() {
      const typeId = this.$route.params.processType;

      var processTalkrounds = this.talkrounds.filter(
        (x) => x.processType === typeId
      );

      if (processTalkrounds.length === 1) {
        return processTalkrounds[0].id;
      }

      var activeTalkrounds = processTalkrounds.filter((x) => x.isActive);
      if (activeTalkrounds.length > 0) {
        return activeTalkrounds[0].id;
      }

      return processTalkrounds[0].id;
    },

    async listTalkrounds() {
      let talkrounds = await talkroundService.listTalkrounds();
      talkrounds = talkrounds.filter(
        (x) => x.processType === this.$route.params.processType
      );

      return talkrounds;
    },

    async loadReport() {
      this.reportData = await reportingService.createReport(
        this.$route.params.reportType,
        this.$route.params.processType,
        this.talkroundId,
        false
      );

      this.loading = false;
    }
  },

  components: {
    statusReportTable
  }
};
</script>
